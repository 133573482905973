

$cols: 3;
$blockWidth: 27%;
$gridMargin: 2%;
$calcWide: 200% + ((100 - $blockWidth * $cols) - ($gridMargin * 2)); //not working right
$calcHeight: 205% + ((100 - ($blockWidth ) * $cols) - ($gridMargin * 2)); //not working right
$aspectRatio: 75%;

.grid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  justify-items: flex-start;
  align-items: flex-start;
  /*max-width: 1200px;*/
  margin: auto;
  margin-bottom: 50px;
  min-height: 500px;
  height: 100%;
}

.block {
  transition: width 1s;
  width: $blockWidth;
  position: relative;
  margin: $gridMargin;
  display: flex;
  flex: 0 1 auto;
  background-size: cover;
  background-position: center;
  background-color:#fbf5f0;
}

.row {
  width: 100%;
  position: relative;
  margin: 0;
  display: flex;
  display: flex;
  justify-content: center;
}


.wide {
  transition: width 1s;
  width: $calcWide;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 10;
  background-size: cover;
  background-position: center;
}

.long {
  transition: height 1s;
  height: 100%;
  height: $calcHeight;
  display: flex;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
}

.block:before {
  content: "";
  display: block;
  padding-top: $aspectRatio;
  /* initial ratio of 4:3*/
}
// .block > .long:before {
//   content: "";
//   display: block;
//   padding-top: 150%;
//   /* initial ratio of 1:1*/
// }

.spacer {
  width: $blockWidth;
  position: relative;
  margin: $gridMargin;
  display: flex;
  flex: 0 1 auto;
}

/*just for demo*/

.blockcut_w {
  width: 100%;
}

.blockcut_l {
  height: 100%;
}

/*Text styling*/

.olio {
  // text-transform: uppercase;
  // font-family: 'Open Sans', sans-serif;
  font-family:"Sometimes Times";
  font-size:3em;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 7%;
  opacity: 1;
  // font-size: 24px;
  margin: auto;
  width: 89%;
  // max-width: 1000px;
  // letter-spacing: 20px;
}

@media (max-width: 600px) {
  .olio {
    font-size:1.6em;

  }
}


.foot-wrap {
  width: 89%;
  margin: auto;
}

.footer-col-wrapper{
  text-align:center;
  font-size:2em;
  font-family:"Sometimes Times";
}